import {
    GridCsvExportOptions,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    useGridApiContext
} from "@mui/x-data-grid";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Link,
    Snackbar,
    TextField
} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import {DownloadForOffline, Downloading} from "@mui/icons-material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import _ from 'lodash'
import ColumnsService from "../../services/columns.service";
import {Alert} from "@mui/lab";
import useAxios from "axios-hooks";

export const GridToolbarButton = ({startIcon, label, onClick, disabled}) => {
    return (
        <Button size="small" startIcon={startIcon} onClick={onClick} disabled={disabled}>
            {label}
        </Button>
    )
}

const CustomToolbar = ({buttons = [], totalExport = true}) => {
    const apiRef = useGridApiContext();
    const handleExport = (options: GridCsvExportOptions) =>
        apiRef.current.exportDataAsCsv(options);

    const [{data: config}, refetchConfig] = useAxios(ColumnsService.columnsUrl(), {
        method: "GET", useCache: false
    })

    const defaultExportModel = useMemo(() => {
        if(!config)
            return null
        const _defaultExportModel = []
        config.defaultExportModel.forEach((col) => {
            _defaultExportModel.push(apiRef.current.getColumn(col))
        })
        return _defaultExportModel
    }, [config])

    return (
        <GridToolbarContainer>
            {<GridToolbarColumnsButton />}
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            {<GridToolbarExport csvOptions={{utf8WithBom: true}}/> /** pulsante export di default */}
            {/*<GridToolbarButton
                startIcon={<Download/>}
                label={"Esporta colonne visibili"}
                onClick={handleExport}
            />*/}
            {/*
                exportedFields &&
                    <GridToolbarButton
                        startIcon={<Downloading/>}
                        label={"Esporta colonne custom"}
                        onClick={() => {
                            handleExport({utf8WithBom: true, fields: exportedFields})
                        }}
                    />
            */}
            {
                totalExport &&
                    <GridToolbarButton
                        startIcon={<DownloadForOffline/>}
                        label={"Esporta tutte le colonne"}
                        onClick={() => {
                            handleExport({utf8WithBom: true, allColumns: true})
                        }}
                    />
            }
            {defaultExportModel && <CustomExportButton apiRef={apiRef} defaultExportModel={defaultExportModel} refetchConfig={refetchConfig} handleExport={handleExport}/>}
            {buttons?.map((button, index) => <div key={index}>{button}</div>) /** eventuali buttons aggiuntivi custom */}
            <Box marginLeft={'auto'}>
                <GridToolbarQuickFilter/>
            </Box>
        </GridToolbarContainer>
    );
}

export default CustomToolbar

const CustomExportButton = ({apiRef, defaultExportModel, refetchConfig, handleExport}) => {
    const [exportState, setExportState] = useState({
        openDialog: false,
        fileName: `Export registrazioni`,
        options: apiRef.current.getAllColumns(),
        selected: defaultExportModel,
    })

    useEffect(() => {
        if(defaultExportModel)
            setExportState({...exportState, selected: defaultExportModel})
    }, [defaultExportModel])

    const [message, setMessage] = useState({show: false, loading: false, text: "", severity: "info"})

    const handleOpenExportDialog = () => {
        refetchConfig()
        setExportState({...exportState, openDialog: true})
    }

    const handleClose = () => {
        setExportState({
            ...exportState,
            openDialog: false,
        })
    }

    const handleCustomExport = () => {
        handleExport({
            fileName: `${exportState.fileName} - ${(new Date()).toLocaleString()}`,
            utf8WithBom: true,
            fields: _.map(exportState.selected, (selectedCol) => (selectedCol.field)),
        })
    }

    const handleSaveConfiguration = () => {
        setMessage({...message, loading: true})
        setTimeout(function () {
            ColumnsService.saveColumsToExport(_.map(exportState.selected, (selectedCol) => (selectedCol.field)))
                .then((res) => {
                    setMessage({show: true, loading: false, text: "Configurazione salvata con successo", severity: "success"})
                })
                .catch((err) => {
                    setMessage({show: true, loading: false, text: "Configurazione non salvata", severity: "error"})
                })
        }, 200);
    }

    const handleCloseError = () => {
        setMessage({...message, show: false})
    }

    return (<>
    <GridToolbarButton
        startIcon={<Downloading/>}
        label={"Esportazione custom"}
        onClick={handleOpenExportDialog}
    />
    <Dialog open={exportState.openDialog} onClose={handleClose} fullWidth maxWidth={"sm"}>
            <DialogTitle>Esporta Dati</DialogTitle>
            <DialogContent>
                <Box my={1}>
                    <TextField fullWidth
                               margin={'dense'}
                               size={'small'}
                               label="Nome del file"
                               defaultValue={exportState.fileName}/>
                    <Autocomplete
                        renderInput={(params) =>
                            <TextField {...params}
                                       label="Scegli le colonne"
                                       margin={'normal'}
                            />}
                        options={exportState.options||[]}
                        multiple
                        limitTags={5}
                        value={exportState.selected}
                        onChange={(event, newSelection) => {
                            setExportState({...exportState, selected: newSelection});
                        }}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.headerName}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.headerName}
                            </li>
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip label={option?.headerName} {...getTagProps({ index: index })} />
                            ))}
                    />
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                    <Link component="button"
                          variant={"body2"}
                          onClick={handleSaveConfiguration}
                          //disabled={_.isEmpty(exportState.selected)}
                    >
                        Salva la configurazione
                    </Link>
                    {message?.loading && <CircularProgress size={18}/>}
                </Box>
                <Snackbar open={message?.show} autoHideDuration={6000} onClose={handleCloseError}>
                    <Alert elevation={6} onClose={handleCloseError} variant={"filled"} severity={message?.severity}
                            sx={{width: '100%'}}>
                        {message?.text}
                    </Alert>
                </Snackbar>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annulla</Button>
                <Button disabled={_.isEmpty(exportState.selected)} variant={'contained'} onClick={handleCustomExport}>Esporta</Button>
            </DialogActions>
    </Dialog>
    </>)
}