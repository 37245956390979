import {API_URL} from "../config";

const usersUrl = ({archived = false, all = false}) => {
    if(!!all && all === true)
        return `${API_URL}users?all=true`
    return !!archived ? `${API_URL}users${archived ? '?archived=false':''}`
        : `${API_URL}users`
}

const userDataUrl = (id) => {
    return `${API_URL}users/${id}`
}

const archiveUserUrl = (id, archive) => {
    return archive ? `${userDataUrl(id)}/archive` : `${userDataUrl(id)}/unarchive`
}

const UserService = {
    usersUrl,
    userDataUrl,
    archiveUserUrl,
};

export default UserService;
