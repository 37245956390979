import {
    AccessTime,
    AlternateEmail,
    CheckBox, CheckBoxOutlineBlank,
    DateRange,
    Link, LocalOffer,
    MenuOpen,
    Notes,
    QrCodeScanner,
    RadioButtonChecked,
    TextFields,
    Today,
    Tune
} from "@mui/icons-material";
import React from "react";

export const fieldTypes = {
    TEXT: {icon: <TextFields color={'disabled'}/>, type: "text", label: "Testo", admin: false},
    EMAIL: {icon: <AlternateEmail color={'disabled'}/>, type: "email", label: "E-mail", admin: false},
    SELECT: {icon: <MenuOpen color={'disabled'}/>, type: "select", label: "Menù a scelta", admin: true},
    DATE: {icon: <DateRange color={'disabled'}/>, type: "date", label: "Data", admin: false},
    DATE_TIME: {icon: <Today color={'disabled'}/>, type: "datetime", label: "Data e Ora", admin: false},
    TIME: {icon: <AccessTime color={'disabled'}/>, type: "time", label: "Ora", admin: false},
    RADIO_GROUP: {icon: <RadioButtonChecked color={'disabled'}/>, type: "radiogroup", label: "Radio group", admin: true},
    CHECK_BOX: {icon: <CheckBox color={'disabled'}/>, type: "checkbox", label: "Checkbox", md: 12, admin: true},
    TEXT_BOX: {icon: <Notes color={'disabled'}/>, type: "textbox", label: "Area di testo", admin: false},
    URL: {icon: <Link color={'disabled'}/>, type: "url", label: "URL", admin: false},
    CUSTOM: {icon: <Tune color={'disabled'}/>, type: "custom", label: "Custom", admin: true},
    ACCESS_TYPE: {icon: <QrCodeScanner color={'disabled'}/>, type: "accessType", label: "Tipo di accesso", admin: true},
    MAILCHIMP_TAGS: {icon: <LocalOffer color={'disabled'}/>, type: "mailchimpTags", label: "Tag MC", admin: true},
}

/**
 *
 * {
 *             "defaultVisible": false,
 *             "id": "mailchimpTags",
 *             "label": "Tag MC",
 *             "precompiled": true,
 *             "type": "mailchimpTags"
 *           },
 * */

export const getLocaleDateTime = ({ts_seconds}) => {
    return new Date(ts_seconds * 1000).toLocaleString('it-IT')
}

export const icon = <CheckBoxOutlineBlank fontSize="small" />;
export const checkedIcon = <CheckBox fontSize="small" />;
