import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
// TODO: Add SDKs for Firebase products that you want to use

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAStz1DNMuRNDXcC_23bx2_7M6vD7GH7Vg",
    authDomain: "mondovi-api-2023.firebaseapp.com",
    projectId: "mondovi-api-2023",
    storageBucket: "mondovi-api-2023.appspot.com",
    messagingSenderId: "805634070664",
    appId: "1:805634070664:web:31affc1b1ffcba3f25673d",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()

export {auth, firebase}